import styled from '@emotion/styled'
import React from 'react'

export const Wrapper = styled.div`
  display: block;
  max-width: 480px;
  margin: 0 30px;
  width: 50vw;

  a {
    transition: 0.3s;
    display: block;
    width: 100%;

    img {
      filter: drop-shadow(0 4px 4px rgb(0 0 0 / 25%));
      width: 100%;
    }

    &:hover {
      transform: scale(1.05);
      opacity: 1;
      transition: 0.6s;
    }
  }
`
type Props = {
  /** クラス名 */
  className?: string
  /** リンク先 */
  idName?: string
}

/**
 * アップデート情報バナー
 */
const UpdateInfoBnr = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={`function-banner${className}`}>
      <a href={props.idName}>
        <img
          src="/images/official/feature/bnrTopUpdateVer02.png"
          alt="ver2.0アップデート情報"
        />
      </a>
    </Wrapper>
  )
}
export default UpdateInfoBnr
