import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'
import OfficialCommonH3Set from '../../molecules/officialCommonH3Set'
import CrownSvg from '../../atoms/crownSvg'

const Wrapper = styled.section`
  width: 100%;
  background: #ffffff;
  position: relative;

  .p-section-inner {
    display: flex;
    max-width: 1200px;
    padding: 80px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;

    .p-section-feature {
      width: 100%;
      display: flex;
      margin: 0 auto 60px;
      justify-content: center;
      gap: 35px;

      .p-feature-01,
      .p-feature-02,
      .p-feature-03 {
        width: calc(100% / 3);
        height: 530px;
        position: relative;
        text-align: center;
        border-radius: 20px;
        border: solid 2px #baf2ed;
        background: #fcffed;
        padding: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        z-index: 1;

        .svg-icon {
          position: absolute;
          left: calc(50% - 21px);
          top: -20px;
        }

        .reason-title {
          width: 100%;
          color: #ffffff;
          margin: 0 auto 20px;
          background: #f2a109;
          height: 108px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: 10px;
          font-size: 30px;
          flex-direction: column;

          span {
            display: block;
            font-size: 22px;
          }
        }

        .detail {
          font-size: 18px;
          line-height: 1.8em;
          padding: 0 30px 30px;
          text-align: left;

          span {
            color: #ff0000;
          }
        }

        .img-wrap {
          position: absolute;
          z-index: 2;

          &.img-01 {
            width: 350px;
            bottom: -30px;
            left: -360px;
          }

          &.img-02 {
            width: 140px;
            bottom: -30px;
            left: -80px;
          }
        }
      }
    }
  }

  ${BreakPoints.xLarge} {
    .p-section-inner {
      padding: 30px 0 0;

      .p-section-feature {
        flex-direction: column;
        gap: 20px;
        margin-top: -20px;

        .p-feature-01,
        .p-feature-02,
        .p-feature-03 {
          width: 90%;
          margin: 0 auto;
          height: auto;
          text-align: left;

          .svg-icon {
            display: none;
          }

          .reason-title {
            height: auto;
            font-size: 24px;
            margin-bottom: 10px;
            padding: 5px;

            span {
              font-size: 18px;
            }
          }

          .detail {
            font-size: 16px;
            padding: 0 10px 10px;
            text-align: left;
          }

          .img-wrap {
            display: none;
          }
        }
      }
    }
  }
`

/**
 * 経費精算-選ばれる理由
 * @constructor
 */
const RequestExpenseReason01 = () => (
  <Wrapper>
    <div className="p-section-inner anim-fade-up">
      <OfficialCommonH3Set label="選ばれる理由" />
      <div className="p-section-feature">
        <div className="p-feature-01">
          <CrownSvg />
          <p className="reason-title txt-bold">電子帳簿保存法に対応</p>
          <p className="detail">
            電子帳簿保存法の要件を満たしているシステムのため、解像度や履歴管理、検索など申請の度に
            <span>電帳法の要件適合をチェックする必要はありません。</span>
          </p>
        </div>
        <div className="p-feature-02">
          <CrownSvg />
          <p className="reason-title txt-bold">
            <span>経費精算の承認ルールを</span>
            細かく設定可能
          </p>
          <p className="detail">
            申請から承認、管理までの煩雑なフローを簡略化し負担を軽減するだけでなく、
            <span>企業独自の承認ルールに合わせて設定可能</span>
            なので、システム導入に伴って従来の運用ルールを変更する必要がありません。
            システム導入時の現場の混乱や担当者の負担を軽減することができます。
          </p>
          <img
            src="/images/official/function/expense/imgReason01.png"
            className="img-wrap img-01"
            alt="すぐに導入、利用開始できる！"
          />
        </div>
        <div className="p-feature-03">
          <CrownSvg />
          <p className="reason-title txt-bold">
            <span>自宅や外出先から</span>
            いつでも申請可能
          </p>
          <p className="detail">
            <span>
              スマートフォンを使ってレシートや領収証をその場で撮影して申請
            </span>
            することができます。
            <br />
            従業員自身が領収証を管理する負担及び紛失するリスクを減らすことができ、出社して経費精算を提出する手間を省きます。{' '}
          </p>
          <img
            src="/images/official/function/expense/imgReason02.png"
            className="img-wrap img-02"
            alt="すぐに導入、利用開始できる！"
          />
        </div>
      </div>
    </div>
  </Wrapper>
)

export default RequestExpenseReason01
