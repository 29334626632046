import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'
import PageBottomArrowSvg from '../../atoms/pageBottomArrowSvg'

const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 50px;

  .p-section-inner {
    max-width: 1200px;
    padding: 80px 0 0;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .p-section-function {
      padding: 30px 60px 30px 60px;
      background: #ffffff;
      border-radius: 10px;
      width: 100%;
      max-width: 1092px;
      margin: 0 auto 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .catch {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: fit-content;
    margin-bottom: 30px;

    .catch-top {
      color: #003777;
      font-weight: bold;
      font-size: 30px;
      width: fit-content;

      span {
        width: fit-content;
        background: #004098;
        padding: 0 34px;
        height: 74px;
        margin: 0 10px;
        border-radius: 100vh;
        color: #fff;
        position: relative;
      }
    }

    .catch-bottom {
      color: #004098;
      font-weight: bold;
      font-size: 43px;
      width: fit-content;

      span {
        background: linear-gradient(transparent 70%, rgb(255, 255, 102) 70%);
        padding: 0 5px;
        font-size: 60px;
      }
    }
  }

  .item-wrap {
    width: 100%;
    overflow: hidden;
    padding-right: 120px;

    .worries {
      float: left;
      background: #efefef;
      border-radius: 8px;
      padding: 20px 30px;
      color: #272d2c;
      font-size: 22px;
      font-weight: bold;
      height: 110px;
      margin-right: 20px;
      margin-bottom: 20px;
      text-align: left;

      span {
        font-size: 26px;
        color: #dd4834;
      }
    }

    .worries-etc {
      margin-top: 35px;
      height: 75px;
    }
  }

  .worries-bottom {
    width: 100%;
    text-align: left;
  }

  .worries-img {
    position: absolute;
    width: 50vw;
    max-width: 400px;
    right: -30px;
    bottom: 20px;
  }

  ${BreakPoints.xLarge} {
    margin-bottom: 20px;

    .p-section-inner {
      padding: 40px 0 0;

      .p-section-function {
        padding: 30px 20px;
        margin: 0 auto 30px;
      }
    }

    .catch {
      margin-bottom: 30px;

      .catch-top {
        font-size: 24px;

        span {
          padding: 0 20px;
          margin: 0 5px;
        }
      }

      .catch-bottom {
        font-size: 28px;

        span {
          font-size: 40px;
        }
      }
    }

    .item-wrap {
      padding-right: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      gap: 15px;
      margin-bottom: 20px;
      width: 100%;

      .worries {
        float: unset;
        width: auto;
        margin: 0;
        padding: 15px 20px;
        font-size: 18px;
        height: auto;

        span {
          font-size: 22px;
        }
      }

      .worries-etc {
        margin-top: 0;
        height: 75px;
      }
    }

    .worries-bottom {
      text-align: center;
    }

    .worries-img {
      display: none;
    }
  }

  ${BreakPoints.medium} {
  }
`

/**
 * 経費精算-お困りごと解決
 * @constructor
 */
const RequestExpenseTop01 = () => (
  <Wrapper>
    <div className="p-section-inner">
      <div className="p-section-function">
        {/** キャッチ部分 */}
        <div className="catch">
          <div className="catch-top">
            このような<span>経費精算</span>に関する
          </div>
          <div className="catch-bottom">
            お困りごとを<span>一気に解決！</span>
          </div>
        </div>
        {/** 事例 */}
        <div className="item-wrap">
          <div className="worries worries-0">
            紙の領収書の
            <br />
            <span>保管が大変</span>
          </div>
          <div className="worries worries-02">
            入力作業に
            <span>
              時間を
              <br />
              取られている
            </span>
          </div>
          <div className="worries worries-02">
            支社・遠隔地の社員の
            <br />
            経費も
            <span>一括管理したい</span>
          </div>
          <div className="worries worries-02">
            領収書の提出のための
            <br />
            <span>出社を無くしたい</span>
          </div>
          <div className="worries worries-02">
            <span>ペーパーレス化</span>を
            <br />
            進めたい
          </div>
          <div className="worries worries-etc">etc...</div>
        </div>
        <div className="worries-bottom">
          自社・在宅・客先常駐など勤務体系を問わずに一括管理できる
          <br />
          便利な経費精算システムです。
          <br />
          紙での管理の不安や、紙での作業を煩わしく感じている皆さまをサポートします。
        </div>
      </div>
      <img
        className="worries-img"
        src="/images/official/function/expense/expenseFunctionWorries01.png"
        alt="経費精算のお悩み"
      />
    </div>
    <div className="text-center">
      <PageBottomArrowSvg />
    </div>
  </Wrapper>
)

export default RequestExpenseTop01
