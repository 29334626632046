import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  padding: 0;
  fill: #addaff;
`

/**
 * SVGアイコンコンポーネント
 * 製品サイトページ下部遷移用矢印アイコン
 */
const PageBottomArrowSvg = (): JSX.Element => (
  <Wrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="144"
      height="54"
      viewBox="0 0 144 54"
    >
      <path
        id="多角形_40"
        data-name="多角形 40"
        d="M72,0l72,54H0Z"
        transform="translate(144 54) rotate(180)"
        stroke="none"
      />
    </svg>
  </Wrapper>
)
export default PageBottomArrowSvg
