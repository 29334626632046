import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'
import OfficialCommonH3Set from '../../molecules/officialCommonH3Set'
import SesManagementHowtoLabel from '../ses-managementHowtoLabel'
import SesManagementHowtoButtonLabel from '../ses-managementHowtoButtonLabel'

const Wrapper = styled.section`
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  padding: 80px 0 45px;
  font-weight: bold;

  .top-explanation {
    font-weight: normal;
    margin-bottom: 50px;
  }

  .p-section-inner {
    max-width: 980px;
    padding: 0 20px;
    margin: 0 auto;
    text-align: center;
    // overflow: hidden;
  }

  .howto-area {
    background-color: #f7f7f7;
    box-shadow: 0 3px 6px #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 20px 25px;
    margin-bottom: 35px;
    position: relative;
  }

  .text-wrap {
    text-align: start;
    margin: 30px 20px;

    p {
      line-height: 30px;
    }

    span {
      color: #f2784c;
    }

    .company-setting-link {
      display: block;
      margin: 20px 0;
    }
  }

  .notice {
    margin-top: 20px;

    p {
      font-weight: normal;
    }
  }

  ${BreakPoints.xLarge} {
    .image-wrap {
      display: none;
    }

    .text-wrap {
      .company-setting-link {
        margin: 20px 0 0 0;
      }
    }
  }

  ${BreakPoints.large} {
    .p-section-inner {
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`

/**
 * 経費精算-利用方法
 * @constructor
 */
const RequestExpenseHowto = () => (
  <Wrapper id="how-to">
    <OfficialCommonH3Set label="利用方法" />
    <div className="p-section-inner">
      <div className="top-explanation">
        <p>
          経費精算機能はオプション機能です。
          <br />
          下記手順でオプションを有効化することで機能のご利用が可能です。
        </p>
      </div>
      <div className="howto-area">
        <SesManagementHowtoLabel number="!" label="オプションの有効化" />
        <div className="text-wrap">
          <p>
            1.
            <a
              href="https://app.kintaicloud.jp/manage/payment"
              target="_blank"
              rel="noreferrer"
            >
              お支払い
            </a>
            にアクセス
          </p>
          <p>
            2. <span>オプション機能</span> ＞ <span>経費精算オプション</span>
            の<SesManagementHowtoButtonLabel label="オプション利用開始" />
            ボタンをクリック
          </p>
          <p>
            3. 表示内容を確認の上
            <SesManagementHowtoButtonLabel label="利用開始" />
            ボタンをクリック
          </p>
          <div className="notice">
            <p>
              ※オプション機能はBusiness/Enterpriseプランをご利用中の企業様に限り利用可能です。
            </p>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default RequestExpenseHowto
