import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import { Link } from 'gatsby'
import BreakPoints from '../../../styles/breakPoints'
import OfficialCommonH3Set from '../../molecules/officialCommonH3Set'
import OfficialFaqItems from '../../molecules/officialFaqItems'
import OfficialCommonBtn from '../../atoms/officialCommonBtn'

const Wrapper = styled.section`
  width: 100%;
  text-align: center;
  padding: 80px 20px;

  .p-section-faq {
    text-align: left;
    max-width: 1080px;
    margin: 0 auto;
  }

  .modal-btn {
    position: relative;
    margin: 5px 0 0 20px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 7px;
      left: -15px;
      border-left: 8px solid #3ec7b3;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }

    a {
      text-decoration: underline solid rgba(255, 255, 255, 0);
      transition: 0.3s all;
      display: inline-block;
      width: unset;
      height: unset;
      padding: 0;
      opacity: 1;

      span {
        font-size: 15px;
        font-weight: normal;
      }

      &:before {
        display: none;
      }

      &:hover {
        opacity: 0.6;
        text-decoration: underline solid #3abd9e !important;

        &:before {
          display: none;
        }
      }
    }

    img {
      display: none !important;
    }
  }

  .text-wrap {
    font-weight: bold;
    text-align: start;
    margin: 30px 20px;

    p {
      line-height: 30px;
    }

    span {
      color: #f2784c;
    }

    .company-setting-link {
      display: block;
      margin: 20px 0;
    }
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0 0;

      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`

/**
 * 経費精算紹介ページ-FAQ
 * @constructor
 */
const RequestExpenseFaq = () => (
  <>
    <Wrapper>
      <OfficialCommonH3Set label="FAQ" className="anim-fade-up" />
      <div className="p-section-faq anim-fade-up">
        <OfficialFaqItems
          question="経費精算機能を試してみたいです。"
          answer={
            '無料トライアル期間中でも経費精算機能をご利用いただくことは可能です。\n' +
            'お試しでのご利用を希望される場合は、「お支払い」より「経費精算オプション」の利用開始手続きをしてください。'
          }
          idName="matter-01"
        >
          <p>
            ※オプション利用の詳細は「
            <Link to="#how-to">利用方法</Link>
            」をご確認ください。
          </p>
        </OfficialFaqItems>
        <OfficialFaqItems
          question="経費精算機能の利用に費用は掛かりますか？"
          answer="Enterpriseプランをご利用中の場合、オプション基本料金は無料でご利用いただくことができます。
          Businessプランご利用の場合は月額10,000円(税抜)でご利用いただけます。"
          idName="matter-02"
        >
          <p>
            詳細は「
            <Link to="#option-price">オプション利用料金</Link>
            」をご確認ください。
          </p>
        </OfficialFaqItems>
        <OfficialFaqItems
          question="オプションを解除はすぐにできますか？"
          answer="オプション機能は「お支払い」からいつでも解除することが可能です。"
          idName="matter-03"
        />
      </div>
      <div className="common-button">
        <OfficialCommonBtn
          size="mega"
          className="anim-fade-up"
          onClick={() => {
            navigate('/function')
          }}
        >
          その他の機能を見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  </>
)

export default RequestExpenseFaq
