import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'
import OfficialCommonH3Set from '../../molecules/officialCommonH3Set'
import RequestExpenseOptionPriceTable from './requestExpenseOptionPriceTable'

const Wrapper = styled.section`
  width: 100%;
  background: #f0f0f0;
  overflow: hidden;
  padding: 80px 0;
  font-weight: bold;

  .p-section-inner {
    max-width: 900px;
    padding: 30px 0 0;
    margin: 0 auto;
    text-align: center;
    // overflow: hidden;
  }

  .margin-bottom {
    margin-bottom: 0;
  }

  .caution-text {
    font-weight: normal;
    text-align: start;
    margin-top: 20px;
    white-space: pre-wrap;
  }

  ${BreakPoints.large} {
    p {
      font-size: 12px;
    }

    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 0;

      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`

/**
 * 経費精算-オプション利用料金
 * @constructor
 */
const RequestExpenseOptionPrice = () => (
  <Wrapper id="option-price">
    <OfficialCommonH3Set label="オプション利用料金" className="margin-bottom" />
    <div className="p-section-inner">
      <div className="img-agent-function">
        <RequestExpenseOptionPriceTable />
        <div className="caution-text">
          <p>
            {'※上記利用料金は税抜価格です。\n' +
              '※基本申請数までは追加料金は発生しません。\n' +
              '※上記利用料金や内容は予告なく変更になる可能性があります。予めご了承ください。'}
          </p>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default RequestExpenseOptionPrice
