import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import OfficialLayout from '../../components/organisms/officialLayout'
import SEO from '../../components/atoms/seo'
import OfficialLowHeader from '../../components/organisms/officialLowHeader'
import RequestExpenseTop01 from '../../components/organisms/expense/requestExpenseTop01'
import RequestExpenseFeature01 from '../../components/organisms/expense/requestExpenseFeature01'
import RequestExpenseFunction01 from '../../components/organisms/expense/requestExpenseFunction01'
import RequestExpenseReason01 from '../../components/organisms/expense/requestExpenseReason01'
import RequestExpenseOptionPrice from '../../components/organisms/expense/requestExpenseOptionPrice'
import RequestExpenseHowto from '../../components/organisms/expense/requestExpenseHowto'
import RequestExpenseFaq from '../../components/organisms/expense/requestExpenseFaq'

const Wrapper = styled.main`
  .top-img {
    max-width: 1100px;
    width: 98vw;
  }

  .function-card {
    display: flex;
    max-width: 400px;
    margin: 0;
    width: auto;
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 機能紹介ページ[経費精算]
 * @constructor
 */
const RequestExpense = ({ location }: IProps) => (
  <OfficialLayout location={location} className="p-page-function">
    <SEO title="経費精算機能" />
    <Wrapper className="main">
      <OfficialLowHeader>
        <img
          className="top-img"
          src="/images/official/function/expense/expenseFunctionTop01.png"
          alt="キンクラ_SES案件管理機能"
        />
      </OfficialLowHeader>
      <RequestExpenseTop01 />
      <RequestExpenseFeature01 />
      <RequestExpenseFunction01 />
      <RequestExpenseReason01 />
      <RequestExpenseOptionPrice />
      <RequestExpenseHowto />
      <RequestExpenseFaq />
    </Wrapper>
  </OfficialLayout>
)

export default RequestExpense

export const pageQuery = graphql`
  query RequestExpense {
    site {
      siteMetadata {
        title
      }
    }
  }
`
