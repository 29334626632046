import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  padding: 0;
  height: 27px;
  width: 42px;
`

/**
 * SVGアイコンコンポーネント
 * 王冠
 */
const CrownSvg = (): JSX.Element => (
  <Wrapper className="svg-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="27.004"
      viewBox="0 0 42 27.004"
    >
      <path
        id="合体_74"
        data-name="合体 74"
        d="M12789.821,20214H12753v-23.732l12.292,7.924,8.705-11.2,8.723,11.215,12.28-7.943V20214Z"
        transform="translate(-12753.001 -20186.998)"
        fill="#fff1b7"
      />
    </svg>
  </Wrapper>
)
export default CrownSvg
