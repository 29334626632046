import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  &.modal-wrapper {
    padding: 15px 10px;

    .modal-window {
      max-width: 1000px;
      padding: 10px;

      .modal-content {
        position: relative;
        max-height: calc(100vh - 40px);

        h4 {
          padding: 10px 0 20px;
        }

        img {
          width: 100%;
          height: 100%;
          margin: 0;
          border: solid 2px #cccccc;
        }
      }
    }
  }
`
type Props = {
  /** クラス名 */
  className?: string
  /** ID名 */
  idName?: string
  /** ボタンラベル */
  label?: string
  /** 画像 */
  fullImg?: string
}

/**
 * アップデート情報表示コンテンツ（ポップアップ表示）
 */
const FunctionPopupContent = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={`modal-wrapper${className}`} id={props.idName}>
      <a href="#!" className="modal-overlay">
        &nbsp;
      </a>
      <div className="modal-window">
        <div className="modal-content">
          {props.label ? <h4>{props.label}</h4> : ' '}
          {props.fullImg && (
            <img
              src={`/images/official/gallery/${props.fullImg}.png`}
              alt="キンクラ画面"
            />
          )}
        </div>
        <a href="#!" className="modal-close">
          ×
        </a>
      </div>
    </Wrapper>
  )
}
export default FunctionPopupContent
