import React from 'react'
import styled from '@emotion/styled'
import { Wrapper } from '../../atoms/updateInfoBnr'
import BreakPoints from '../../../styles/breakPoints'

const Card = styled.div`
  a {
    width: 400px;
    background-color: #ffffff;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    // overflow: hidden;
    box-shadow: 0 2px 10px #ababab;
    position: relative;
    overflow: hidden;

    .icon-wrap {
      height: 90px;
      width: 110px;
      position: absolute;
      top: 0;
      right: 0;
      background: #ffffff;
      border-radius: 0 5px 0 5px;
      box-shadow: -2px 1px 3px rgba(0, 0, 0, 0.3);
      padding: 15px 5px 10px;

      img {
        height: 90%;
        width: auto;
      }
    }
  }

  img {
    filter: none !important;
  }

  &:hover * {
    text-decoration: rgba(0, 0, 0, 0) none !important;
  }

  .image-area {
    height: 250px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 225px;
  }

  .label-area {
    width: fit-content;

    h3 {
      font-size: 33px;
      color: #313a38;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
        background-color: #51d3c7;
        bottom: -10px;
        left: 0;
      }
    }
  }

  .text-area {
    display: flex;
    height: 60px;
    color: #272d2c;
    white-space: pre-wrap;
    font-size: 18px;
    align-items: center;
    padding-bottom: 30px;
  }

  ${BreakPoints.xLarge} {
    a {
      width: 45vw;
      max-width: 400px;

      .icon-wrap {
        height: 80px;
        width: 100px;
        padding: 10px 0 0;

        img {
          height: 80%;
        }
      }
    }

    .image-area {
      height: auto;
    }

    .wrap {
      height: 180px;
    }

    .label-area {
      h3 {
        font-size: 20px;
      }
    }

    .text-area {
      display: flex;
      height: auto;
      font-size: 16px;
      padding: 0 10px 20px;
      white-space: unset;
    }
  }
`

type Props = {
  /** クラス名 */
  className?: string
  /** リンク先 */
  idName?: string
  /** 機能名 */
  label?: string
  /** 説明 */
  description?: string
  /** 画像 */
  fullImg?: string
  /** アイコン */
  icon: string
}

/**
 * 主な機能；ホバー用カード
 */
const FunctionPopupCard = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={`function-card${className}`}>
      <Card>
        <a href={props.idName}>
          <div className="icon-wrap">
            <img
              src={`/images/official/function/expense/${props.icon}.png`}
              alt="キンクラ経費精算の主な機能"
            />
          </div>
          <div className="image-area">
            <img
              src={`/images/official/gallery/${props.fullImg}.png`}
              alt="キンクラ経費精算の主な機能"
            />
          </div>
          <div className="wrap">
            <div className="label-area">
              <h3>{props.label}</h3>
            </div>
            <div className="text-area">{props.description}</div>
          </div>
        </a>
      </Card>
    </Wrapper>
  )
}
export default FunctionPopupCard
