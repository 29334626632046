import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: start;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 3px 6px #d7d7d7;
  width: fit-content;

  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5px;
  }

  li {
    // border: 1px solid #ff0000;
    height: 80px;
    border-top: 1px solid #ebebeb;
    border-right: 1px dashed #ebebeb;
    //width: 260px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 10px 10px 0;
      // border: 1px solid #0000ff;
      border-radius: 5px;
      height: calc(100% - 20px);
      text-align: center;
      line-height: 38px;
      flex-direction: column;
      width: 265px;
      font-size: 18px;
      color: #454f63;

      .free {
        font-size: 24px;
      }

      .alpha {
        margin: -15px 0 0;
        display: block;
        font-size: 16px;
        opacity: 0.5;
        font-weight: normal;
      }
    }

    &.left-cell {
      div {
        background-color: #f0f0f0;
        width: 280px;
        color: #505050;
        text-align: left;
        align-items: self-start;
        padding: 20px;
        font-size: 16px;
      }
    }
  }

  .line-1 {
    margin-bottom: 5px;

    li {
      border: none;
      height: 60px;

      div {
        width: 272px;
      }
    }

    .null-cell div {
      background-color: #ffffff;
      width: 280px;
    }

    .business-cell div {
      background-color: #ffebb5;
      font-size: 28px;
    }

    .enterprise-cell div {
      background-color: #ffd167;
      font-size: 28px;
    }

    div {
      margin: 0 5px;
      height: 100%;
      line-height: 58px;
      border-radius: 2px;
    }
  }

  .line-2,
  .line-3 {
    margin-left: 15px;
  }

  // テキストが2行あるときの行の高さ
  .text-2line {
    line-height: 19px;
  }

  .ribbon {
    margin: -4px 0 0;
    display: block;
    position: relative;
    height: 20px;
    line-height: 20px;
    text-align: center;
    padding: 0 20px;
    font-size: 14px;
    background: #ff5c9a;
    color: #fff;
    box-sizing: border-box;
  }

  .ribbon:before,
  .ribbon:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    z-index: 1;
  }

  .ribbon:before {
    top: 0;
    left: 0;
    border-width: 10px 0 10px 7px;
    border-color: transparent transparent transparent #ffffff;
    border-style: solid;
  }

  .ribbon:after {
    top: 0;
    right: 0;
    border-width: 10px 7px 10px 0;
    border-color: transparent #ffffff transparent transparent;
    border-style: solid;
  }

  ${BreakPoints.xLarge} {
    width: 100vw;

    ul {
      flex-wrap: wrap;

      li {
        width: 49%;
        border-top: none;

        &:last-child {
          border-right: none;
        }

        div {
          width: 100%;
          margin-top: 0;
        }

        &.left-cell {
          width: 98%;
          height: auto;
          border: none;

          div {
            width: 100%;
            display: block;
            height: auto;
            padding: 0;
            text-align: center;
            margin-bottom: 5px;
            margin-top: 3px;
            border: none;
          }
        }

        &.null-cell {
          display: none;
        }

        &.business-cell,
        &.enterprise-cell {
          div {
            width: 100%;
          }
        }
      }
    }
  }

  ${BreakPoints.large} {
    p {
      font-size: 14px;
    }
  }
`

/**
 * 経費精算-料金表
 * @constructor
 */

const RequestExpenseOptionPriceTable = (): JSX.Element => (
  // const {  = '' } = props

  <Wrapper>
    <ul className="line-1">
      <li className="left-cell null-cell">
        <div />
      </li>
      <li className="business-cell">
        <div>Business</div>
      </li>
      <li className="enterprise-cell">
        <div>Enterprise</div>
      </li>
    </ul>
    <ul className="line-2">
      <li className="left-cell">
        <div>オプション基本料金（月額）</div>
      </li>
      <li>
        <div>
          10,000円/月
          <p className="ribbon">初月無料</p>
        </div>
      </li>
      <li>
        <div>
          <span className="free">無料</span>
        </div>
      </li>
    </ul>
    <ul className="line-2">
      <li className="left-cell">
        <div>社員の案件管理</div>
      </li>
      <li>
        <div>50件/月</div>
      </li>
      <li>
        <div>
          20件/月～<span className="alpha">※社員50人毎に10件追加</span>
        </div>
      </li>
    </ul>
    <ul className="line-3">
      <li className="left-cell">
        <div>追加料金（税抜）</div>
      </li>
      <li>
        <div>1,500円/10件</div>
      </li>
      <li>
        <div>1,000円/10件</div>
      </li>
    </ul>
  </Wrapper>
)

export default RequestExpenseOptionPriceTable
