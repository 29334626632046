import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'
import OfficialCommonH3Set from '../../molecules/officialCommonH3Set'
import FunctionPopupCard from './functionPopupCard'
import FunctionPopupContent from './functionPopupContent'

const Wrapper = styled.section`
  width: 100%;
  background: #deeff0;
  padding: 80px 0;
  font-weight: bold;
  position: relative;

  .p-section-inner {
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 35px 25px;
  }

  ${BreakPoints.xLarge} {
    padding: 30px 0;

    .p-section-inner {
      gap: 25px;
    }
  }

  ${BreakPoints.medium} {
  }
`

/**
 * 経費精算-主な機能
 * @constructor
 */
const RequestExpenseFunction01 = () => (
  <>
    <Wrapper>
      <OfficialCommonH3Set label="主な機能" />
      <div className="p-section-inner">
        <FunctionPopupCard
          label="タイムスタンプの付与"
          idName="#modal-01"
          fullImg="thum18_card"
          icon="functionIcon01"
          description={'領収書アップロート時に\n自動的にタイムスタンプを付与'}
        />
        <FunctionPopupCard
          label="検索機能"
          idName="#modal-02"
          fullImg="thum19_card"
          icon="functionIcon02"
          description={'申請データは日付・金額・利用先で\n検索が可能'}
        />
        <FunctionPopupCard
          label="領収書のデータ管理"
          idName="#modal-03"
          fullImg="thum20_card"
          icon="functionIcon03"
          description="データの訂正・追加・削除履歴を管理"
        />
        <FunctionPopupCard
          label="ファイル要件の自動判定"
          idName="#modal-04"
          fullImg="thum21_card"
          icon="functionIcon04"
          description={
            '保存要件に適合したファイルか\n自動判定し、要件を満たしていない\n場合は警告を表示'
          }
        />
        <FunctionPopupCard
          label="帳票出力"
          idName="#modal-05"
          fullImg="thum22_card"
          icon="functionIcon05"
          description={'経費精算の申請内容\n領収書ファイルを出力可能'}
        />
        <FunctionPopupCard
          label="支払ステータス管理"
          idName="#modal-06"
          fullImg="thum23_card"
          icon="functionIcon06"
          description={'支払状況のステータスを管理し\n対応状況を残すことが可能'}
        />
      </div>
    </Wrapper>
    <FunctionPopupContent
      label="タイムスタンプの付与"
      idName="modal-01"
      fullImg="thum18"
    />
    <FunctionPopupContent label="検索機能" idName="modal-02" fullImg="thum19" />
    <FunctionPopupContent
      label="領収書のデータ管理"
      idName="modal-03"
      fullImg="thum20"
    />
    <FunctionPopupContent
      label="ファイル要件の自動判定"
      idName="modal-04"
      fullImg="thum21"
    />
    <FunctionPopupContent label="帳票出力" idName="modal-05" fullImg="thum22" />
    <FunctionPopupContent
      label="支払ステータス管理"
      idName="modal-06"
      fullImg="thum23"
    />
  </>
)

export default RequestExpenseFunction01
