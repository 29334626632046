import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  background-image: url(/images/official/function/expense/expenseFunctionFeatureBg01.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;

  .p-section-inner {
    max-width: 1200px;
    padding: 40px 40px 30px;
    margin: 0 auto;
    border-radius: 10px;
    border: solid 2px #ffffff;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(/images/official/function/expense/expenseFunctionFeatureBg02.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      width: 100%;
      max-width: 1000px;
      margin-bottom: 30px;
      text-shadow: -1px -3px 3px #ffffff;

      .title-top {
        color: #004098;
        font-weight: bold;
        font-size: 75px;
        width: fit-content;

        span {
          font-size: 34px;
          display: block;
          margin: -15px 0 0;
        }
      }

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: calc((100% - 300px) / 2);
        height: 10px;
        left: 0;
        top: 50%;
        border-radius: 2px;
        background: #ffda41;
        z-index: 0;
      }

      &:after {
        left: unset;
        right: 0;
      }
    }

    .p-section-function {
      padding: 30px 40px;
      background: #ffffff;
      border-radius: 10px;
      width: 100%;
      max-width: 1000px;
      margin: 0 auto 20px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;

      .feature-area {
        display: flex;
        margin: 0 0 10px 0;
        justify-content: flex-start;
        align-items: center;

        .img-wrap {
          img {
            width: 30px;
            height: auto;
            margin: 6px 10px 0 0;
          }
        }

        .txt-wrap {
          font-weight: 600;
          color: #272d2c;
          font-size: 28px;
        }
      }

      .detail {
        font-size: 20px;
        text-align: left;

        span {
          background: linear-gradient(transparent 60%, rgb(255, 255, 102) 60%);
          padding: 0 5px;
        }
      }
    }
  }

  ${BreakPoints.xLarge} {
    padding: 0;

    .p-section-inner {
      padding: 20px 20px 10px;
      border-radius: 10px;

      .title {
        margin-bottom: 15px;

        .title-top {
          font-size: 45px;

          span {
            font-size: 18px;
            margin: -8px 0 0;
          }
        }

        &:before,
        &:after {
          width: calc((100% - 180px) / 2);
          height: 5px;
        }
      }

      .p-section-function {
        padding: 10px 20px;
        width: 90vw;
        margin: 0 auto 15px;

        .feature-area {
          .img-wrap {
            img {
              width: 20px;
            }
          }

          .txt-wrap {
            font-size: 22px;
            text-align: left;
          }
        }

        .detail {
          font-size: 14px;

          span {
            background: linear-gradient(
              transparent 60%,
              rgb(255, 255, 102) 60%
            );
            padding: 0 5px;
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
  }
`

/**
 * 案件管理機能紹介ページ-最上部キャッチ
 * @constructor
 */
const RequestExpenseFeature01 = () => (
  <Wrapper>
    <div className="p-section-inner fade-in-auto delay-3">
      <div className="title">
        <div className="title-top">
          特長<span>キンクラ経費精算</span>
        </div>
      </div>
      {/** 特長 */}
      <div className="p-section-function">
        <div className="feature-area">
          <p className="img-wrap">
            <img
              src="/images/official/function/expense/expenseFunctionFeatureCheck01.png"
              alt="よくあるお悩み"
              className="img-wrap"
            />
          </p>
          <p className="txt-wrap txt-size-26">
            シンプルな機能で、導入・利用が簡単！
          </p>
        </div>
        <div className="detail">
          複雑になりがちな経費精算システムを、必要最低限の機能で
          <span>簡単に利用が開始できます。</span>
        </div>
      </div>
      {/** 特長 */}
      <div className="p-section-function">
        <div className="feature-area">
          <p className="img-wrap">
            <img
              src="/images/official/function/expense/expenseFunctionFeatureCheck01.png"
              alt="キンクラ経費精算の特長"
              className="img-wrap"
            />
          </p>
          <p className="txt-wrap txt-size-26">
            勤怠管理と経費精算両方使えて、追加料金０円
          </p>
        </div>
        <div className="detail">
          勤務表と交通費の提出等の勤怠管理だけではなく
          <br />
          案件管理と経費精算管理も<span>キンクラだけで完結</span>します。
        </div>
      </div>
      {/** 特長 */}
      <div className="p-section-function">
        <div className="feature-area">
          <p className="img-wrap">
            <img
              src="/images/official/function/expense/expenseFunctionFeatureCheck01.png"
              alt="キンクラ経費精算の特長"
              className="img-wrap"
            />
          </p>
          <p className="txt-wrap txt-size-26">
            電子帳簿保存法に対応した経費精算機能
          </p>
        </div>
        <div className="detail">
          スマホ撮影やスキャナから取り込んだデータの保存にも対応しているので
          <br />
          今まで発生していた<span>書類管理の手間を軽減</span>
          し、申請／承認／管理が簡単に行なえます。
        </div>
      </div>
    </div>
  </Wrapper>
)

export default RequestExpenseFeature01
